import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./blog.css";
import Container from "@mui/material/Container";
import blog1card from "../../images/blog1card.png";
import blog2card from "../../images/blog2card.png";
import blog3card from "../../images/blog3card.png";
import blog4card from "../../images/blog4card.png";
import blog5card from "../../images/blog5card.png";
import blog6card from "../../images/blog6card.png";
import blog7card from "../../images/blog7card.png";
import blog8card from "../../images/blog8cover.png";
import Contact from "../../components/contact/Contact";
import { Helmet } from "react-helmet";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>EASTHAI - บทความและข่าวสาร</title>
      </Helmet>
      <div className="blogHeader">
        <div className="blogHeaderContent">
        <h1>EASTHAI BLOG</h1>
        <h3>บทความทั้งหมด</h3>
        </div>
      </div>
      <Container className="blogContents">
        <div className="blogContent">
          <div className="blogCard">
            <Link to="/blog/fcl-lcl">
              <img src={blog5card} alt="FCL LCL คืออะไร" />
              <div className="blogCard-info">
                <div className="meta-info">
                  <p className="date">September 27, 2023</p>
                  <p className="reading-time">3 mins read</p>
                </div>
                <h2>
                  FCL และ LCL ต่างกันอย่างไร
                  การขนส่งสินค้าทางทะเลในโลกธุรกิจที่คุณควรรู้
                </h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/animal-feed-customs-clearance">
              <img src={blog2card} alt="นำเข้าอาหารสัตว์" />
              <div className="blogCard-info">
                <div className="meta-info">
                  <p className="date">May 23, 2023</p>
                  <p className="reading-time">5 mins read</p>
                </div>
                <h2>
                  นำเข้าอาหารสัตว์ ต้องเริ่มต้นจากอะไร
                  สามารถนำอะได้เข้ามาได้บ้าง
                </h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/form-fta">
              <img src={blog3card} alt="form fta ลดภาษี" />
              <div className="blogCard-info">
                <div className="meta-info">
                  <p className="date">August 23, 2023</p>
                  <p className="reading-time">5 mins read</p>
                </div>{" "}
                <h2>
                  ข้อควรรู้และข้อควรระวังในการใช้สิทธิประโยชน์ทางการค้า และ Form
                  ต่างๆ
                </h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/shippingbroker">
              <img src={blog4card} alt="หน้าที่ชิปปิ้ง" />
              <div className="blogCard-info">
              <div className="meta-info">
                  <p className="date">September 27, 2023</p>
                  <p className="reading-time">8 mins read</p>
                </div>
                <h2>Shipping (ตัวแทนออกของ) มีหน้าที่อะไร </h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/shippingmark">
              <img src={blog1card} alt="หน้าที่ชิปปิ้ง" />
              <div className="blogCard-info">
              <div className="meta-info">
                  <p className="date">June 12, 2024</p>
                  <p className="reading-time">5 mins read</p>
                </div>
                <h2>
                  Shipping Mark คืออะไร เรื่องน่ารู้สำหรับการนำเข้าที่ถูกต้อง
                </h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/cosmetics">
              <img src={blog6card} alt="หน้าที่ชิปปิ้ง" />
              <div className="blogCard-info">
              <div className="meta-info">
                  <p className="date">June 12, 2024</p>
                  <p className="reading-time">8 mins read</p>
                </div>
                <h2>คู่มือการนำเข้า เครื่องสำอาง: วิธีการและข้อกำหนด</h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/machinery">
              <img src={blog7card} alt="หน้าที่ชิปปิ้ง" />
              <div className="blogCard-info">
              <div className="meta-info">
                  <p className="date">July 1, 2024</p>
                  <p className="reading-time">5 mins read</p>
                </div>
                <h2>คู่มือการนำเข้า เครื่องจักร: วิธีการและข้อกำหนด</h2>
              </div>
            </Link>
          </div>
          <div className="blogCard">
            <Link to="/blog/import-tax">
              <img src={blog8card} alt="หน้าที่ชิปปิ้ง" />
              <div className="blogCard-info">
              <div className="meta-info">
                  <p className="date">July 7, 2024</p>
                  <p className="reading-time">2 mins read</p>
                </div>
                <h2>การจัดเก็บภาษีมูลค่าเพิ่มสำหรับของที่มีราคาไม่เกิน 1,500 บาท</h2>
              </div>
            </Link>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default Blog;
