// src/data/shipmentData.js

const shipmentInfo = [
  {
    id: "EAS0001",
    bl_or_awb: "TEST001",
    size: '40" ft',
    qty: '8',
    pod: "LCH",
    eta: "10/04/24",
    dem: "10 days",
    remark: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero, animi.",
    route: "IZMIR - BANGKOK",
  },
    {
      id: "EAS1103234",
      bl_or_awb: "ANRLCH102938",
      size: '40" ft',
      qty: '8',
      pod: "LCH",
      eta: "10/04/24",
      dem: "10 days",
      remark: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero, animi.",
      route: "SHANGHAI - BANGKOK",
    },
    {
      id: "EAS1103235",
      bl_or_awb: "BNRCLH203948",
      size: '20" ft',
      qty: '8',
      pod: "LCH",
      eta: "10/06/24",
      dem: "5 days",
      remark: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem.",
      route: "LONDON - BANGKOK",
    },
    {
      id: "EAS1103236",
      bl_or_awb: "CNFCLH102348",
      size: '45" ft',
      qty: '8',
      pod: "LKB",
      eta: "11/04/24",
      dem: "8 days",
      remark: "Ut enim ad minima veniam, quis nostrum exercitationem ullam.",
      route: "SINGAPORE - BANGKOK",
    },
    {
      id: "EAS1103237",
      bl_or_awb: "DHLCLH563948",
      size: '30" ft',
      qty: '8',
      pod: "LKB",
      eta: "09/04/24",
      dem: "7 days",
      remark: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit.",
      route: "SYDNEY - BANGKOK",
    },
    {
      id: "EAS1103238",
      bl_or_awb: "KRTCLH273948",
      size: '35" ft',
      qty: '8',
      pod: "LCH",
      eta: "10/10/24",
      dem: "6 days",
      remark: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
      route: "TOKYO - BANGKOK",
    },
    {
      id: "EAS1103239",
      bl_or_awb: "MNLCLH134598",
      size: '40" ft',
      qty: '8',
      pod: "LCH",
      eta: "11/01/24",
      dem: "10 days",
      remark: "Consectetur, adipisci velit, sed quia non numquam eius modi tempora.",
      route: "MANILA - BANGKOK",
    },
    {
      id: "EAS1103240",
      bl_or_awb: "HKGCLH193948",
      size: '50" ft',
      qty: '8',
      pod: "LKB",
      eta: "10/22/24",
      dem: "4 days",
      remark: "Labore et dolore magnam aliquam quaerat voluptatem.",
      route: "HONG KONG - BANGKOK",
    },
    {
      id: "EAS1103241",
      bl_or_awb: "SINCLH123948",
      size: '20" ft',
      qty: '8',
      pod: "LKB",
      eta: "09/28/24",
      dem: "9 days",
      remark: "Ut enim ad minima veniam, quis nostrum exercitationem ullam.",
      route: "SINGAPORE - BANGKOK",
    },
    {
      id: "EAS1103242",
      bl_or_awb: "JPNCLH342948",
      size: '60" ft',
      qty: '8',
      pod: "LCH",
      eta: "12/01/24",
      dem: "12 days",
      remark: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit.",
      route: "TOKYO - BANGKOK",
    },
    {
      id: "EAS1103243",
      bl_or_awb: "AUSCLH563948",
      size: '25" ft',
      qty: '8',
      pod: "LCH",
      eta: "11/15/24",
      dem: "7 days",
      remark: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
      route: "SYDNEY - BANGKOK",
    },
  ];
  
  const shipmentStatus = [
    {
      shipmentId: "EAS0001",
      date: "APR 10, 2024",
      time: "12:00 am",
      status: "Shipment created",
      details: ["Document Review"],
      completed: true,
      timestamp: "2024-04-10T00:00:00Z",
    },
    {
      shipmentId: "EAS0001",
      date: "APR 10, 2024",
      time: "4:59 pm",
      status: "Customs Preparation",
      details: [
        "Waiting for shipment arrive",
        "Additional Information Required",
        "Scheduled for Inspection",
      ],
      completed: true,
      delayed: false,
      timestamp: "2024-04-30T16:59:00Z",
    },
    {
        shipmentId: "EAS0001",
        date: "APR 14, 2024",
        time: "12:00 am",
        status: "Customs Cleared",
        details: ["Waiting for destination", "Inspection In Progress"],
        completed: true,
        timestamp: "2024-05-01T00:00:00Z",
      },
      {
        shipmentId: "EAS0001",
        date: "APR 15, 2024",
        time: "12:00 am",
        status: "Delivered",
        details: [
          "Duty Assessment",
          "Payment Pending",
          "Payment Confirmed",
          "Under Customs Review",
        ],
        completed: true,
        delayed: false,
        timestamp: "2024-04-15T00:00:00Z",
      },
    {
      shipmentId: "EAS1103234",
      date: "APR 10, 2024",
      time: "12:00 am",
      status: "Shipment created",
      details: ["Document Review"],
      completed: true,
      timestamp: "2024-05-03T00:00:00Z",
    },
    {
      shipmentId: "EAS1103234",
      date: "APR 10, 2024",
      time: "4:59 pm",
      status: "Customs Preparation",
      details: [
        "Waiting for shipment arrive",
        "Additional Information Required",
        "Scheduled for Inspection",
      ],
      completed: true,
      delayed: false,
      timestamp: "2024-04-10T16:59:00Z",
    },
    {
        shipmentId: "EAS1103234",
        date: "APR 14, 2024",
        time: "12:00 am",
        status: "Customs Cleared",
        details: ["Waiting for destination", "Inspection In Progress"],
        completed: true,
        timestamp: "2024-04-14T00:00:00Z",
      },
      {
        shipmentId: "EAS1103234",
        date: "APR 15, 2024",
        time: "12:00 am",
        status: "Delivered",
        details: [
          "Duty Assessment",
          "Payment Pending",
          "Payment Confirmed",
          "Under Customs Review",
        ],
        completed: true,
        delayed: false,
        timestamp: "2024-04-15T00:00:00Z",
      },
    {
      shipmentId: "EAS1103235",
      date: "APR 11, 2024",
      time: "1:00 pm",
      status: "Shipment created",
      details: ["Document Review"],
      completed: true,
      delayed: false,
      timestamp: "2024-04-11T13:00:00Z",
    },
    {
      shipmentId: "EAS1103235",
      date: "APR 11, 2024",
      time: "5:00 pm",
      status: "Customs Preparation",
      details: [
        "Waiting for shipment arrive",
        "Additional Information Required",
        "Scheduled for Inspection",
      ],
      completed: true,
      delayed: false,
      timestamp: "2024-04-11T17:00:00Z",
    },
    {
        shipmentId: "EAS1103235",
        date: "APR 14, 2024",
        time: "12:00 am",
        status: "Customs Cleared",
        details: ["Waiting for destination", "Inspection In Progress"],
        completed: true,
        timestamp: "2024-04-14T00:00:00Z",
      },
      {
        shipmentId: "EAS1103235",
        date: "APR 15, 2024",
        time: "12:00 am",
        status: "Delivered",
        details: [
          "Duty Assessment",
          "Payment Pending",
          "Payment Confirmed",
          "Under Customs Review",
        ],
        completed: false,
        delayed: false,
        timestamp: "2024-04-15T00:00:00Z",
      },
    {
      shipmentId: "EAS1103236",
      date: "APR 12, 2024",
      time: "8:00 am",
      status: "Shipment created",
      details: ["Document Review"],
      completed: true,
      delayed: false,
      timestamp: "2024-04-12T08:00:00Z",
    },
    {
      shipmentId: "EAS1103236",
      date: "APR 12, 2024",
      time: "2:00 pm",
      status: "Customs Preparation",
      details: [
        "Waiting for shipment arrive",
        "Additional Information Required",
        "Scheduled for Inspection",
      ],
      completed: true,
      delayed: true,
      timestamp: "2024-04-12T14:00:00Z",
    },
    {
        shipmentId: "EAS1103236",
        date: "APR 14, 2024",
        time: "12:00 am",
        status: "Customs Cleared",
        details: ["Waiting for destination", "Inspection In Progress"],
        completed: false,
        timestamp: "2024-04-14T00:00:00Z",
      },
      {
        shipmentId: "EAS1103236",
        date: "APR 15, 2024",
        time: "12:00 am",
        status: "Delivered",
        details: [
          "Duty Assessment",
          "Payment Pending",
          "Payment Confirmed",
          "Under Customs Review",
        ],
        completed: false,
        delayed: false,
        timestamp: "2024-04-15T00:00:00Z",
      },
    {
      shipmentId: "EAS1103237",
      date: "APR 13, 2024",
      time: "9:00 am",
      status: "Shipment created",
      details: ["Document Review"],
      completed: true,
      delayed: false,
      timestamp: "2024-04-13T09:00:00Z",
    },
    {
      shipmentId: "EAS1103237",
      date: "APR 13, 2024",
      time: "3:00 pm",
      status: "Customs Preparation",
      details: [
        "Waiting for shipment arrive",
        "Additional Information Required",
        "Scheduled for Inspection",
      ],
      completed: true,
      delayed: true,
      timestamp: "2024-04-13T15:00:00Z",
    },
    {
      shipmentId: "EAS1103237",
      date: "APR 14, 2024",
      time: "12:00 am",
      status: "Customs Cleared",
      details: ["Waiting for destination", "Inspection In Progress"],
      completed: true,
      timestamp: "2024-04-14T00:00:00Z",
    },
    // Add more statuses for other shipments...
  ];
  
  export { shipmentStatus, shipmentInfo };
  