import React, { useState } from "react";
import "./searchTracking.css";
import CircularProgress from '@mui/material/CircularProgress';

const SearchTracking = ({ onSearch }) => {
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    onSearch(searchInput);
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate a delay for the loading indicator
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const clearInput = () => {
    setSearchInput("");
  };

  return (
    <>
      <header className="ShipmentTracking-header">
        <h1>EASTHAI Tracking</h1>
        <div className="search-container">
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="COMING SOON...."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {searchInput && (
              <button className="clear-button" onClick={clearInput}>
                ×
              </button>
            )}
          </div>
          <button onClick={handleSearch} className="searchBtn">
            {loading ? <CircularProgress size={18} color="inherit"/> : "Track"}
          </button>
        </div>
        <p>
          Easily track your shipment with our tracking system designed to keep
          you informed.
        </p>
      </header>
    </>
  );
};

export default SearchTracking;
