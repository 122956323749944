import React, { useRef } from "react";
import "./home.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Box from "@mui/material/Box";
import OurService from "../../components/ourService/OurService";
import GetQuotation from "../../components/getQuotation/GetQuotation";
// import Form from "../../components/form/Form";
import SliderBrandTestimonial from "../../components/sliderBrandTestimonial/SliderBrandTestimonial";
import Slider from "../../components/slider/Slider";
import Contact from "../../components/contact/Contact";
import Faq from "../../components/faq/Faq";
import B2bSolutions from "../../components/b2bSolutions/B2bSolutions";
import abouthome from "../../images/shipnow.svg";
import step1 from "../../images/step1.png";
import step5 from "../../images/step5.png";
import step6 from "../../images/step6.png";
import step7 from "../../images/step7.png";

import homeImg2 from "../../images/homeImg2.png";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from '@mui/material/Tooltip';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const aboutHomeRef = useRef(null);

  const handleScroll = () => {
    window.scrollTo({
      top: aboutHomeRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>EASTHAI - บริการ ขนส่ง สินค้าระหว่างประเทศ </title>
      </Helmet>
      <div className="header">
        <div className="headerContainer">
          <div className="headerText">
            <h1>EASTHAI</h1>
            <h5>EST. 1994</h5>
            <h2>บริการ ขนส่ง สินค้าระหว่างประเทศ </h2>
            <h3>
              สำหรับขอใบเสนอราคา กรอกข้อมูลสำหรับเจ้าหน้าที่เพื่อทำการติดต่อกลับ
            </h3>

            <div className="homeBtn">
              <Tooltip title="Coming soon..." arrow>
              <Button
                variant="contained"
                color="primary"
                to="/trackandtrace"
                component={Link}
                className="navBtn"
              >
                Tracking my shipment <ConnectingAirportsIcon />
              </Button>
              </Tooltip>
              <Button
                variant="contained"
                color="primary"
                to="/quotation"
                component={Link}
                className="navBtn"
              >
                Get quotation <SendIcon />
              </Button>
            </div>
            <div className="scrollBtn">
              <span className="curvedText">Read More</span>
              <ExpandMoreIcon
                onClick={handleScroll}
                className="arrowDownIcon"
              />
            </div>
          </div>

          <div className="homeImg">
            <img src={homeImg2} alt="Home" />
          </div>
        </div>
      </div>

      {/* Header end */}

      <div className="partnerLogo">
        <SliderBrandTestimonial />
      </div>

      {/* Partner end */}

      <div className="aboutHome" ref={aboutHomeRef}>
        <div className="aboutHomeText">
          <h5>About us</h5>
          <h1>ตัวแทนของผู้ขนส่งสินค้าระหว่างประเทศ</h1>
          <p>
            ผู้ให้บริการขนส่งสินค้าระหว่างประเทศรวมถึงการจัดการด้านศุลกากรให้กับผู้นำเข้าส่งออก
            เรามีเชี่ยวชาญและชำนาญในการประเมินค่าภาษี ตรวจสอบการจัดประเภทสินค้า
            และจัดเตรียมเอกสารที่จำเป็นสำหรับการขนส่ง
            ทุกขั้นตอนของการจัดการเป็นไปตามมาตรฐานและข้อบังคับเพื่อให้แน่ใจว่าคุณจะได้รับบริการที่รวดเร็ว
            ปลอดภัย อย่างมืออาชีพ
          </p>

          <p>
            We provide international shipping services, including customs
            management for importers and exporters. Our expertise lies in tax
            assessment, product classification, and the preparation of necessary
            shipping documents. Every step of our process adheres to standards
            and regulations to ensure you receive fast, secure, and professional
            service.
          </p>
        </div>
        <div className="aboutHomeImg">
          <img src={abouthome} alt="East Asia Shipping (Thailand)" />
        </div>
      </div>

      {/* About end */}

      <div className="ourService-home">
        <OurService />
      </div>

      {/* Section3 */}
      <div className="section3">
        <div className="section3Title">
          <h1>พิธีการศุลกากรนำเข้าสินค้า</h1>
        </div>
        <Box
          className="boxCardHome"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "1rem",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="cardHome">
            <div
              className="cardTop"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <img src={step5} alt="นำเข้าส่งออก" />
            </div>
            <div className="cardContent">
              <h3>ปรึกษาและคำนวนภาษี</h3>
              <p>
                เพื่อให้เข้าใจถึงกระบวนการและหลักเกณฑ์ที่เกี่ยวข้อง
                ช่วยให้คุณสามารถวางแผนต้นทุนสำหรับสินค้าได้อย่างมีประสิทธิภาพ
              </p>
            </div>
          </div>
          <div className="cardHome">
            <div
              className="cardTop"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <img src={step6} alt="นำเข้าส่งออก" />
            </div>
            <div className="cardContent">
              <h3>ปรึกษาเกี่ยวกับใบอนุญาต</h3>
              <p>
                บริการให้คำปรึกษาเกี่ยวกับใบอนุญาตนำเข้า เช่น อย., มอก., สมอ.,
                และแก้ไขปัญหาหากสินค้าของคุณติดปัญหาด่านศุลกากร
              </p>
            </div>
          </div>

          <div className="cardHome">
            <div
              className="cardTop"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
                <img src={step1} alt="นำเข้าส่งออก" />
            </div>
            <div className="cardContent">
              <h3>ปัญหาเอกสารไม่ถูกต้อง</h3>
              <p>
                บริการแก้ไขและจัดเตรียมเอกสารที่สำคัญเพื่อการเคลียร์สินค้าให้อย่างราบรื่น
              </p>
            </div>
          </div>

          <div className="cardHome">
            <div
              className="cardTop"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <img src={step7} alt="นำเข้าส่งออก" />
            </div>
            <div className="cardContent">
              <h3>ให้คำปรึกษาการใช้ฟอร์มนำเข้า</h3>
              <p>
                ให้คำปรึกษาเกี่ยวกับการใช้ฟอร์มนำเข้าต่างๆ
                เพื่อช่วยเหลือในการลดหย่อนภาษีให้กับผู้นำเข้า
              </p>
            </div>
          </div>
        </Box>
        <Slider />
        <div className="viewmore-btn">
          <Link to="/customclearance">
            <button>พิธีการศุลกากร คือ</button>
          </Link>
          <Link to="/blog/shippingbroker">
            <button>หน้าที่ของชิปปิ้ง</button>
          </Link>
        </div>

        {/* Our Service end */}

        <B2bSolutions />
        <div class="homeFaqContainer">
          <div class="homeFaqBackground"></div>
          <div class="homeFaq">
            <Faq />
          </div>
        </div>
        <GetQuotation />
      </div>

      <Contact />
    </>
  );
};

export default Home;
