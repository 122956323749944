import React, { useState } from "react";
import "./ourService.css";
import { Link } from "react-router-dom";

import air_icon from "../../images/air_icon.svg";
import sea_icon from "../../images/sea_icon.svg";
import express_icon from "../../images/express_icon.svg";
import customs_icon from "../../images/customs_icon.svg";
import truck_icon from "../../images/truck_icon.svg"

import air_img from "../../images/air_3.png";
import sea_img from "../../images/service_sea.png";
import courier_img from "../../images/service_courier.png";
import customs_img from "../../images/service-customs.png";
import truck_img from "../../images/service_truck.png";

const services = [
  {
    id: "customclearance",
    icon: customs_icon,
    title: "Customs clearance",
    descriptionTH:
      "บริการดำเนินพิธีการศุลกากร เตรียมงานเอกสารและสำแดงภาษีให้กับกรมศุลกากร เราเป็นตัวแทนในการเดินพิธีการศุลกากรและเคลียร์สินค้าทั้งขาออกขาเข้าประเทศ",
    descriptionEN:
      "Customs clearance services, preparing documents and declaring taxes to the Customs Department, representing both export and import clearance.",
    link: "/customclearance",
    img: customs_img,
  },
  {
    id: "courier",
    icon: express_icon,
    title: "Express Support",
    descriptionTH:
      "บริการช่วยเหลือสำหรับผู้นำเข้าสินค้าเร่งด่วน (Courier express) Fedex, DHL, Tnt พร้อมแก้ใขปัญหาการนำเข้าสินค้าจากต่างประเทศ",
    descriptionEN:
      "Support services for urgent import shipments (Courier express) like Fedex, DHL, TNT, solving import issues from abroad.",
    link: "/courier",
    img: courier_img,
  },
  {
    id: "airfreight",
    icon: air_icon,
    title: "Air Freight",
    descriptionTH:
      "การขนส่งทางอากาศเหมาะสำหรับพัสดุ สินค้าขนาดเล็กที่ต้องการความรวดเร็ว ช่วยให้มั่นใจได้ว่าสินค้าจะถึงปลายทางได้อย่างรวดเร็ว",
    descriptionEN:
      "Air freight is suitable for small packages that require speed, ensuring that the goods reach their destination quickly.",
    link: "/airfreight",
    img: air_img,
  },
  {
    id: "seafreight",
    icon: sea_icon,
    title: "Sea Freight",
    descriptionTH:
      "การขนส่งสินค้าที่มีปริมาณมากหรือสินค้าที่มีขนาดใหญ่โดยการขนส่งทางเรือสามารถแบ่งออกเป็นสองประเภทคือ FCL และ LCL",
    descriptionEN:
      "Sea freight is ideal for large volumes or bulky goods and can be divided into two types: FCL and LCL.",
    link: "/seafreight",
    img: sea_img,
  },
  {
    id: "inlandtransport",
    icon: truck_icon,
    title: "Inland Transportation",
    descriptionTH:
      "เราให้บริการขนส่งด้วยรถบรรทุกที่ครอบคลุมทั่วประเทศ มุ่งสู่จุดหมายปลายทางของคุณอย่างมีประสิทธิภาพ",
    descriptionEN:
      "We offer comprehensive truck transportation services across the country, ensuring timely delivery to your destination.",
    link: "/inlandtransportation",
    img: truck_img,
  },
];

const OurService = () => {
  const [activeService, setActiveService] = useState(services[0].id);

  return (
    <div className="container">
      <div className="ourService">
        <div className="ourServiceBox">
          <h1>Our service</h1>
          <div className="h5Container">
            <h5 className="english">
              We provide international freight services, covering air, sea, and
              inland transportation, along with customs clearance services to
              ensure your shipments are smooth and efficient
            </h5>
            <h5 className="thai">
              เราให้บริการขนส่งสินค้าระหว่างประเทศ ครอบคลุมการขนส่งทางอากาศ
              ทางทะเล และในประเทศ พร้อมบริการพิธีการศุลกากร
              เพื่อให้การขนส่งของคุณราบรื่นและมีประสิทธิภาพ
            </h5>
          </div>
        </div>

        <div className="btnContainer">
          {services.map((service) => (
            <button
              key={service.id}
              onClick={() => setActiveService(service.id)}
              className={activeService === service.id ? "active" : ""}
            >
              {service.title}
            </button>
          ))}
        </div>
        {services.map(
          (service) =>
            activeService === service.id && (
              <div key={service.id} className="ourServiceBox2">
                <img
                  src={service.img}
                  alt={service.title}
                  className="serviceImage"
                />
                <div className="serviceText">
                  <img src={service.icon} alt="" />
                  {/* <h1>{service.title}</h1> */}
                  <p>{service.descriptionTH}</p>
                  <p>{service.descriptionEN}</p>
                  <Link to={service.link}>
                    <p>อ่านเพิ่มเติม</p>
                  </Link>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default OurService;
