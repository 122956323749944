import React, { useEffect } from "react";
import Contact from "../contact/Contact";
import Sidebar from "../sidebar/Sidebar";
import BlogShare from "../blogShare/BlogShare";
import blog1cover from "../../images/blog1cover.png";
import blog1cover1 from "../../images/blog1cover1.png";
import "./easblog.css";
import ProgressBar from "../progressbar/Progressbar";

const Easblog = () => {
  const url = "https://www.easthai.co/blog/shipping-freight";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="easblog">
        <div className="blogArticle">
          <div className="blogContainer">
            <div className="blogArticleHeader">
              <div>June 12, 2024</div>
              <h1>
                Shipping Mark คืออะไร เรื่องน่ารู้สำหรับการนำเข้าที่ถูกต้อง
              </h1>
              <div>By EASTHAI Team</div>
            </div>
            <img src={blog1cover1} alt="Blog post" />
            <section>
              <h3>Introduction</h3>
              <p>
                การนำเข้าและส่งออกสินค้าเป็นกระบวนการที่ซับซ้อนที่ต้องการความละเอียดและการปฏิบัติตามกฎหมายศุลกากร
                พ.ศ. 2560 การระบุ Shipping Marks & Nos.
                บนสินค้าและเอกสารที่เกี่ยวข้องไม่เพียงช่วยให้กระบวนการเป็นไปอย่างราบรื่นแต่ยังช่วยลดความเสี่ยงในการถูกปรับและการตรวจสอบจากเจ้าหน้าที่
                การปฏิบัติตามข้อกำหนดเหล่านี้จะเพิ่มความน่าเชื่อถือและประสิทธิภาพในธุรกิจของคุณ
              </p>
              <p>
                บทความนี้จะพาคุณทำความเข้าใจข้อกำหนดในการระบุ Shipping Marks &
                Nos. ตามกฎหมายศุลกากร พ.ศ. 2560
                รวมถึงตัวอย่างและข้อยกเว้นเพื่อให้คุณปฏิบัติตามกฎหมายได้อย่างถูกต้องและครบถ้วนนอกจากนี้ยังมีข้อมูลเกี่ยวกับการประกาศกรมศุลกากรที่สำคัญในการนำเข้าสินค้า
              </p>
              <p>
                การทำความเข้าใจและปฏิบัติตามข้อกำหนดเหล่านี้จะช่วยให้คุณดำเนินธุรกิจนำเข้า-ส่งออกได้อย่างมั่นใจและมีประสิทธิภาพลดความเสี่ยงในการถูกปรับและการตรวจสอบจากเจ้าหน้าที่ศุลกากร
              </p>
            </section>
            <section>
              <h3>การปฏิบัติตามกฎหมาย</h3>
              <p>
                การปฏิบัติตามกฎหมายในการระบุเครื่องหมายและเลขหมายสินค้าบนบรรจุภัณฑ์
                (Shipping Marks) เป็นสิ่งสำคัญ
                โดยต้องเขียนชื่อย่อหรือสัญลักษณ์ให้ชัดเจนบนบรรจุภัณฑ์และระบุในเอกสารที่เกี่ยวข้อง
                เช่น commercial invoice, B/L, และ packing list
                เพื่อให้กระบวนการนำเข้า-ส่งออกเป็นไปอย่างราบรื่น
              </p>
              <h3>ข้อยกเว้นและแนวทางปฏิบัติ</h3>
              <p>
                เมื่อสินค้ามีภาพหรือข้อความที่เป็นภาษาต่างประเทศ ให้ใช้คำว่า
                "PICTURE" แทน ตามประกาศกรมศุลกากร พระราชบัญญัติศุลกากร พ.ศ. 2560
                มาตรา 211 ระบุว่า “ผู้นำเข้าหรือผู้ส่งออกผู้ใด
                ไม่มีเครื่องหมายหรือเลขหมายกำกับหีบห่อหรือภาชนะบรรจุของหรือไม่แสดงเครื่องหมายหรือเลขหมายไว้ในเอกสารที่เกี่ยวกับของต้องระวางโทษปรับไม่เกิน
                50,000 บาท”
              </p>
            </section>
            <section>
              <h3>ตัวอย่างการระบุ Shipping Marks </h3>
              <ul>
                <li>ชื่อผู้ส่งออกหรือชื่อบริษัท: เช่น XYZ CO.,LTD.</li>
                <li>ประเทศที่ผลิตสินค้า: เช่น Made in Thailand</li>
                <li>รายละเอียดสินค้าหรือรหัสสินค้า: เช่น ABC PARTS</li>
                <li>ชนิด ขนาด น้ำหนัก เช่น Carton , Pallet , Weight</li>
                <li>จำนวนบรรจุภัณฑ์: เช่น carton กล่องที่ 1-5</li>
              </ul>
              <p>
                ** ให้ระบุลำดับหีบห่อและจำนวนหีบห่อทั้งหมดให้ชัดเจน เช่น
                กล่องที่ 1-10 เพื่อความสะดวกและความถูกต้องในการตรวจนับสินค้า
              </p>

              <h3>ประโยชน์ในการระบุ Shipping Marks</h3>
              <p>
                การระบุ Shipping Marks & Nos.
                อย่างถูกต้องและครบถ้วนช่วยให้การนำเข้า-ส่งออกเป็นไปอย่างราบรื่นลดความเสี่ยงการถูกปรับและตรวจสอบจากเจ้าหน้าที่ศุลกากร
              </p>
              <ul>
                <li>
                  ช่วยระบุสินค้า:
                  ทำให้สามารถระบุได้ว่าสินค้าใดเป็นของใครและมาจากไหน
                </li>
                <li>
                  ลดความเสี่ยงการสูญหายหรือผิดพลาด:
                  ทำให้การจัดการและการขนส่งสินค้าเป็นไปอย่างราบรื่น
                </li>
              </ul>
              <h3>ข้อควรคำนึง</h3>
              <ul>
                <li>
                  การปฏิบัติตามข้อกำหนดเหล่านี้ช่วยให้การนำเข้า-ส่งออกเป็นไปตามกฎหมายเพิ่มความเชื่อถือและประสิทธิภาพในการจัดการสินค้าระหว่างประเทศ{" "}
                </li>
                <li>
                  การระบุ Shipping Marks ที่ถูกต้องช่วยลดหย่อนอัตราภาษีภายใต้
                  FORM FTA โดยการเชื่อมโยงกับข้อมูลที่ถูกต้องจริงในเอกสาร INV
                  PKG และ FORM
                </li>
              </ul>
            </section>

            <section>
            <img src={blog1cover} alt="Blog post" />
              <h1>“NO SHIPPING MARK ”</h1>

              <h3>
                กรณีเป็นสินค้าผ่อนผันไม่ต้องสำแดง Shipping Marks
                แต่ให้ระบุในใบขนสินค้าขาเข้าว่า “NO SHIPPING MARK ”
              </h3>
              <ul>
                <li>หีบห่อของใช้ส่วนตัว เช่น เสื้อผ้า หนังสือ รองเท้า</li>
                <li>หีบห่อของที่ได้รับเอกสิทธิ์ เช่น ของบริจาค</li>
                <li>ของที่มิได้บรรจุหีบห่อ เช่น รถยนต์ ยางรถยนต์</li>
                <li>ของเหลวบรรจุในขวดใหญ่ เช่น น้ำกรด</li>
                <li>ของที่มาเป็นกอง เช่น ถ่านหิน</li>
                <li>ของที่บรรจุหีบห่อเดียว</li>
                <li>ของที่บรรจุในหีบห่อมาตรฐาน เช่น ลังไม้</li>
                <li>
                  ของในถังเหล็กหรือไฟเบอร์ เช่น น้ำยาดับกลิ่น น้ำมันเครื่อง
                </li>
                <li>ของในถุงกระดาษหรือกระสอบ เช่น ปุ๋ย แอมโมเนีย แป้ง</li>
              </ul>

              <h3>สรุปการใช้งานเครื่องหมาย Shipping mark</h3>
              <p>
                การระบุ Shipping Marks & Nos.
                เป็นข้อกำหนดทางกฎหมายที่ต้องปฏิบัติตามและเป็นขั้นตอนสำคัญในการจัดการและตรวจสอบสินค้านำเข้า-ส่งออกการปฏิบัติตามข้อกำหนดเหล่านี้ช่วยลดความเสี่ยงในการถูกปรับและเสริมสร้างความน่าเชื่อถือในการดำเนินธุรกิจระหว่างประเทศ
              </p>
              <p>
                ทั้งนี้กระบวนการในการตรวจปล่อยและปัญหาที่อาจพบเมื่อมีการนำเข้าสินค้าระหว่างประเทศทีมงานของเรามีใบอนุญาตตัวแทนออกของและได้รับการรับรองผ่านกรมศุลกากรเราเข้าใจทุกระเบียบที่เกี่ยวข้องด้วยความเชี่ยวชาญในอุตสาหกรรมนี้เราพร้อมแก้ไขปัญหา
                และ
                ให้ข้อมูลที่เป็นประโยชน์พร้อมดำเนินการตามข้อกำหนดและข้อปฏิบัติเพื่อให้แน่ใจว่าสินค้าจะได้รับการดูแลในระหว่างการขนส่งอย่างปลอดภัยหรือถูกจัดเก็บไว้ในที่ปลอดภัยตามข้อกำหนดอย่างเคร่งครัด
              </p>
              <p>
                หากท่านสนใจนำเข้าสินค้าสามารถติดต่อ EASTHAI
                ผู้เชี่ยวชาญในการดำเนินพิธีการศุลกากร
                พร้อมดูแลให้ทุกขั้นตอนสะดวก รวดเร็ว ตอบโจทย์ครบวงจร
                เบอร์โทรติดต่อ 086-380-8450 หรือ 02-235-9100 #27
              </p>
            </section>

            <BlogShare url={url} />
            <ProgressBar />
          </div>
        </div>
        <div className="sidebarComponent">
          <Sidebar />
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Easblog;
