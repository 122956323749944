import React, { useState, useEffect } from "react";
import "./trackandtrace.css";
import { Container } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import CustomsTracking from "../../components/customsTracking/CustomsTracking";
import SearchTracking from "../../components/searchTracking/SearchTracking";
import { shipmentInfo } from "../../data";
import contactcover from '../../images/contactcover.png';

const Trackandtrace = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchedShipment, setSearchedShipment] = useState(null);
  const [searchError, setSearchError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = (searchInput) => {
    setLoading(true);
    setSearchError(false);
    setSearchedShipment(null);

    setTimeout(() => {
      const shipment = shipmentInfo.find(
        (info) => info.id === searchInput || info.bl_or_awb === searchInput
      );
      if (shipment) {
        setSearchedShipment(shipment);
        setSearchError(false);
      } else {
        setSearchedShipment(null);
        setSearchError(true);
      }
      setLoading(false);
    }, 2000); // Simulate an async search with a timeout
  };

  return (
    <div className="ShipmentTracking">
      <SearchTracking onSearch={handleSearch} />
      
      {loading && (
        <div className="loading-container">
          <LinearProgress  color="inherit"/>
        </div>
      )}

      {searchError && (
        <div className="searcherror">
          <div className="searcherror-text">
            <h1>No results found.</h1>
            <p>
              We couldn't find a shipment matching the provided ID or BL/AWB
              number. Try search again.
            </p>
            {/* <span className="error-btn"><a href="/contactform">Contact us</a></span> */}
          </div>
        </div>
      )}

      {searchedShipment && <CustomsTracking shipmentId={searchedShipment.id} />}

      <Container className="contact-container">
        <div className="contact-text">
          <h1>Start Shipping Today</h1>
          <p>
            Our customer service team is always ready to help you with parcel
            tracking questions. You may reach our customer service team on the
            Contact Us page.
          </p>

          <p>Start shipping today and contact us for details. </p>
          <a href="/contactform" className="contact-link">
            Contact Us
          </a>
        </div>
        <div className="contact-image">
          <img src={contactcover} alt="Contact Us" />
        </div>
      </Container>
    </div>
  );
};

export default Trackandtrace;
