import React from "react";
import "./customsTracking.css";
import { Container } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReplyIcon from "@mui/icons-material/Reply";
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import AddBoxIcon from '@mui/icons-material/AddBox';

import { shipmentStatus, shipmentInfo } from "../../data";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  position: "relative",
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#007F5F" : "#063A4F",
  },
}));

const calculateProgress = (statuses) => {
  const completedSteps = statuses.filter((step) => step.completed).length;
  return ((completedSteps - 1) / 3) * 100; // Always divide by 3 since we have 4 steps
};

const CustomsTracking = ({ shipmentId }) => {
  const currentShipmentInfo = shipmentInfo.find(info => info.id === shipmentId);
  const currentShipmentStatus = shipmentStatus.filter(status => status.shipmentId === shipmentId);


  
  const progress = calculateProgress(currentShipmentStatus);

  const steps = [
    {
      status: "Shipment created",
      icon: AddBoxIcon,
    },
    {
      status: "Customs Preparation",
      icon: AssignmentIcon,
    },
    {
      status: "Customs Cleared",
      icon: AssignmentTurnedInIcon,
    },
    {
      status: "Delivered",
      icon: LocalShippingIcon,
    },
  ];

  const currentStatus = steps
    .slice()
    .reverse()
    .find((step) => currentShipmentStatus.some((status) => status.status === step.status && status.completed))?.status || "Unknown";

  return (
    <Container className="result-container">
      <div className="shipment-id">
        <div className="shipment-header">
          <div>
            <h3>
              Tracking ID: <span>{currentShipmentInfo.id}</span>
            </h3>
            <p>This tracking is provided by EASTHAI only.</p>
            <span className="shipment-route">
              <DirectionsBoatIcon className="icon" />
              {currentShipmentInfo.route}
            </span>
          </div>
          <div className="shipment-actions">
            <Tooltip title="Share this shipment" arrow>
              <button className="icon-button">
                <ReplyIcon /> Share
              </button>
            </Tooltip>
            <Tooltip title="Email notification when shipment is updated" arrow>
              <button className="icon-button">
                <NotificationsIcon /> Add Tracking
              </button>
            </Tooltip>
          </div>
        </div>
        <Container className="progress-info">
          <div className="progress-container">
            <BorderLinearProgress variant="determinate" value={progress} />
            {steps.map((step, index) => {
              const status = currentShipmentStatus.find((s) => s.status === step.status);
              const IconComponent = step.icon;

              return (
                <div
                  key={index}
                  className="status-icon-container"
                  style={{
                    left: `${index * (100 / (steps.length - 1))}%`,
                  }}
                >
                  <Tooltip title={step.status} arrow>
                    <IconComponent className={`shipping-icon ${status && status.completed ? "completed" : ""}`} />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </Container>
        <div className="shipment-info-container">
          <div className="shipment-status">
            <h3>Shipment Status : <span>{currentStatus}</span></h3>
            {steps.map((step, index) => {
              const status = currentShipmentStatus.find((s) => s.status === step.status);
              return (
                <div key={index} className="status-update">
                  <div className="status-progress">
                    <CheckCircleIcon className={`status-icon ${status && status.completed ? "completed" : ""}`} />
                  </div>
                  <div className="status-date">
                    <p>{status ? status.date : "N/A"}</p>
                    <p>{status ? status.time : "N/A"}</p>
                    {status && status.delayed && (
                      <Tooltip title="Shipment delayed due to Shipping lined" arrow>
                        <p className="status-delayed">DELAYED</p>
                      </Tooltip>
                    )}
                  </div>
                  <div className="status-detail">
                    <h4 className={status && status.completed ? "completed" : ""}>
                      {step.status}
                    </h4>
                    {status && status.completed && (
                      <ul>
                        {status.details.map((detail, idx) => (
                          <li key={idx}>{detail}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="shipment-info">
            <h3>Shipment Info</h3>
            <p>
              ID :<strong> {currentShipmentInfo.id}</strong>{" "}
            </p>
            <p>
              B/L or AWB :<strong> {currentShipmentInfo.bl_or_awb}</strong>{" "}
            </p>
            <p>
              Size :<strong> {currentShipmentInfo.size}</strong>{" "}
            </p>
            <p>
              Qty :<strong> {currentShipmentInfo.qty}</strong>{" "}
            </p>
            <p>
              POD :<strong> {currentShipmentInfo.pod}</strong>{" "}
            </p>
            <p>
              ETA (estimate) :<strong> {currentShipmentInfo.eta}</strong>{" "}
            </p>
            <p>
              DEM :<strong> {currentShipmentInfo.dem}</strong>{" "}
            </p>
            <p>
              Remark* :
              <strong> {currentShipmentInfo.remark}</strong>{" "}
            </p>
            <button>Contact us</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CustomsTracking;


// import React from "react";
// import "./customsTracking.css";
// import { Container } from "@mui/material";
// import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
// import { styled } from "@mui/material/styles";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ReplyIcon from "@mui/icons-material/Reply";
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import Tooltip from "@mui/material/Tooltip";
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
// import AddBoxIcon from '@mui/icons-material/AddBox';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 15,
//   borderRadius: 5,
//   position: "relative",
//   width: "100%",
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#00B894" : "#063A4F",
//   },
// }));

// const calculateProgress = (statuses) => {
//   const completedSteps = statuses.filter((step) => step.completed).length;
//   return ((completedSteps - 1) / 3) * 100; // Always divide by 3 since we have 4 steps
// };

// const CustomsTracking = ({ shipment }) => {
//   const { trackingId, status, events, shipmentInfo } = shipment;

//   const progress = calculateProgress(events);

//   const steps = [
//     {
//       status: "Shipment created",
//       icon: AddBoxIcon,
//     },
//     {
//       status: "Customs Preparation",
//       icon: AssignmentIcon,
//     },
//     {
//       status: "Customs Cleared",
//       icon: AssignmentTurnedInIcon,
//     },
//     {
//       status: "Delivered",
//       icon: LocalShippingIcon,
//     },
//   ];

//   const currentStatus = steps
//     .slice()
//     .reverse()
//     .find((step) => events.some((event) => event.status === step.status && event.completed))?.status || "Unknown";

//   return (
//     <Container className="result-container">
//       <div className="shipment-id">
//         <div className="shipment-header">
//           <div>
//             <h3>
//               Tracking ID: <span>{trackingId}</span>
//             </h3>
//             <p>This tracking is provided by EASTHAI only.</p>
//             <span className="shipment-route">
//               <DirectionsBoatIcon className="icon" />
//               {shipmentInfo.route}
//             </span>
//           </div>
//           <div className="shipment-actions">
//             <Tooltip title="Share this shipment" arrow>
//               <button className="icon-button">
//                 <ReplyIcon /> Share
//               </button>
//             </Tooltip>
//             <Tooltip title="Email notification when shipment is updated" arrow>
//               <button className="icon-button">
//                 <NotificationsIcon /> Add Tracking
//               </button>
//             </Tooltip>
//           </div>
//         </div>
//         <div className="progress-info">
//           <div className="progress-container">
//             <BorderLinearProgress variant="determinate" value={progress} />
//             {steps.map((step, index) => {
//               const status = events.find((s) => s.status === step.status);
//               const IconComponent = step.icon;

//               return (
//                 <div
//                   key={index}
//                   className="status-icon-container"
//                   style={{
//                     left: `${index * (100 / (steps.length - 1))}%`,
//                   }}
//                 >
//                   <Tooltip title={step.status} arrow>
//                     <IconComponent className={`shipping-icon ${status && status.completed ? "completed" : ""}`} />
//                   </Tooltip>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         <div className="shipment-info-container">
//           <div className="shipment-status">
//             <h3>Shipment Status : <span>{currentStatus}</span></h3>
//             {steps.map((step, index) => {
//               const status = events.find((s) => s.status === step.status);
//               return (
//                 <div key={index} className="status-update">
//                   <div className="status-progress">
//                     <CheckCircleIcon className={`status-icon ${status && status.completed ? "completed" : ""}`} />
//                   </div>
//                   <div className="status-date">
//                     <p>{status ? new Date(status.date).toLocaleDateString() : "N/A"}</p>
//                     <p>{status ? new Date(status.date).toLocaleTimeString() : "N/A"}</p>
//                     {status && status.delayed && (
//                       <Tooltip title="Shipment delayed due to Shipping lined" arrow>
//                         <p className="status-delayed">DELAYED</p>
//                       </Tooltip>
//                     )}
//                   </div>
//                   <div className="status-detail">
//                     <h4 className={status && status.completed ? "completed" : ""}>
//                       {step.status}
//                     </h4>
//                     {status && status.completed && (
//                       <ul>
//                         {status.details.split(',').map((detail, idx) => (
//                           <li key={idx}>{detail}</li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <div className="shipment-info">
//             <h3>Shipment Info</h3>
//             <p>
//               ID :<strong> {trackingId}</strong>{" "}
//             </p>
//             <p>
//               B/L or AWB :<strong> {shipmentInfo.blNo}</strong>{" "}
//             </p>
//             <p>
//               Size :<strong> {shipmentInfo.size}</strong>{" "}
//             </p>
//             <p>
//               Qty :<strong> {shipmentInfo.qty}</strong>{" "}
//             </p>
//             <p>
//               POD :<strong> {shipmentInfo.pod}</strong>{" "}
//             </p>
//             <p>
//               ETA (estimate) :<strong> {new Date(shipmentInfo.eta).toLocaleDateString()}</strong>{" "}
//             </p>
//             <p>
//               DEM :<strong> {shipmentInfo.dem}</strong>{" "}
//             </p>
//             <p>
//               Remark* :<strong> {shipmentInfo.remark}</strong>{" "}
//             </p>
//             <button>Contact us</button>
//           </div>
//         </div>
//       </div>
//     </Container>
//   );
// };

// export default CustomsTracking;
