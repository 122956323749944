import React, { useEffect } from "react";
import Contact from "../contact/Contact";
import Sidebar from "../sidebar/Sidebar";
import BlogShare from "../blogShare/BlogShare";
import blog6cover from "../../images/blog6cover.png";
import "./easblog6.css";
import ProgressBar from "../progressbar/Progressbar";

const Easblog = () => {
  const url = "https://www.easthai.co/blog/cosmetics";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="easblog">
        <div className="blogArticle">
          <div className="blogContainer">
            <div className="blogArticleHeader">
              <div>June 12, 2024</div>
              <h1>คู่มือการนำเข้า เครื่องสำอาง: วิธีการและข้อกำหนด</h1>
              <div>By EASTHAI Team</div>
            </div>
            <img src={blog6cover} alt="Blog post" />
            <section>
              <h3>Introduction</h3>
              <p>
                การนำเข้าเครื่องสำอางเป็นกระบวนการที่ต้องปฏิบัติตามขั้นตอนและข้อกำหนดทางกฎหมายเพื่อให้มั่นใจว่าเครื่องสำอางที่นำเข้ามานั้นปลอดภัยและถูกต้องตามมาตรฐานที่กำหนด
                บทความนี้จะนำเสนอขั้นตอนหลักและข้อกำหนดที่สำคัญในการนำเข้าเครื่องสำอาง
              </p>
              <p>
                ปัจจุบัน
                เครื่องสำอางทุกชนิดเป็นเครื่องสำอางควบคุมตามกฎหมายศุลกากร
                ถือเป็น <span className="highlight">"ของต้องกำกัด"</span>
              </p>
              <p>
                เครื่องสำอางเป็นสินค้าที่มีความละเอียดอ่อนและมีความสำคัญต่อผู้บริโภค
                ดังนั้น
                การนำเข้าจึงต้องปฏิบัติตามกฎหมายที่เกี่ยวข้องอย่างเคร่งครัด
                เพื่อให้มั่นใจว่าเครื่องสำอางที่นำเข้าได้รับการตรวจสอบและรับรองความปลอดภัย
                ข้อกำหนดหลักๆ ได้แก่:
                การทำความเข้าใจและปฏิบัติตามข้อกำหนดเหล่านี้จะช่วยให้คุณดำเนินธุรกิจนำเข้า-ส่งออกได้อย่างมั่นใจและมีประสิทธิภาพลดความเสี่ยงในการถูกปรับและการตรวจสอบจากเจ้าหน้าที่ศุลกากร
              </p>
              <ul>
                <li>
                  เครื่องสำอางต้องได้รับอนุญาตจากหน่วยงานที่รับผิดชอบก่อนการนำเข้า
                </li>
                <li>
                  การยื่นเรื่องขออนุญาตนำเข้าต่อสำนักงานคณะกรรมการอาหารและยา
                  (อย.) หากนำเข้ามาขายโดยไม่มีใบอนุญาตจะผิดกฎหมายตาม
                  พรบ.เครื่องสำอาง พ.ศ. 2558 และ พรบ.ศุลกากร พ.ศ. 2560
                  หากไม่มีใบอนุญาตในการนำเข้าจะมีโทษปรับทางศุลกากร
                </li>
              </ul>
            </section>
            <section>
              <h3>เอกสารที่เกี่ยวข้องกับการดำเนินพิธีการ</h3>
              <p>
                สำนักงานคณะกรรมการอาหารและยา (อย.)
                เป็นหน่วยงานหลักที่กำกับดูแลเครื่องสำอางในประเทศไทย
                เครื่องสำอางทุกชนิดที่นำเข้าต้องปฏิบัติตามระเบียบของ อย.
                เพื่อให้สามารถทำการตลาดในประเทศได้อย่างถูกกฎหมาย
              </p>
              <h3>การจำแนกผลิตภัณฑ์</h3>
              <ul>
                <li>
                  เครื่องสำอางทั่วไป: ไม่อ้างสรรพคุณทางรักษา เช่น แชมพู โลชั่น
                  และเครื่องสำอางแต่งหน้า
                </li>
                <li>
                  เครื่องสำอางควบคุมพิเศษ: อ้างสรรพคุณเฉพาะ เช่น
                  ครีมลดริ้วรอยและครีมรักษาสิว
                  และต้องมีการควบคุมที่เข้มงวดมากขึ้น
                </li>
              </ul>
            </section>
            <section>
              <h3>เอกสารที่เกี่ยวข้องกับการดำเนินพิธีการ</h3>
              <ul>
                1. INVOICE (บัญชีราคาสินค้า)
                <li>
                  รายละเอียดผู้ขายและผู้ซื้อ: ระบุชื่อ, ที่อยู่,
                  และข้อมูลติดต่อของผู้ขายและผู้ซื้อ
                  ในกรณีใช้สิทธิพิเศษทางการค้า FTA ข้อมูลส่วนนี้ต้องตรงกับเอกสาร
                  FORM C/O และเอกสารใบตราส่งสินค้า BILL OF LOADING
                </li>
                <li>
                  รายละเอียดสินค้า: รายละเอียดของสินค้าและบริการที่ถูกสั่งซื้อ
                  รวมถึงปริมาณ ราคาต่อหน่วย และมูลค่ารวม ในกรณีสินค้าที่เป็น
                  FREE OF CHARGE (FOC) ต้องมีสถานะในใบขนสินค้าว่าเป็น FOC
                  และราคาต่อหน่วยของตัว FOC ต้องมีราคาต่อหน่วยไม่น้อยกว่า 60%
                  ของราคาตลาด
                </li>
                <li>
                  ราคาและเงื่อนไขในการชำระเงิน:
                  ระบุราคาทั้งหมดโดยราคาที่ระบุไม่ต่ำกว่า 60% ของราคาตลาดใน
                  Invoice มิฉะนั้น PO (PRE-ORDER)
                  เพื่อวัตถุประสงค์ตรวจสอบราคาต้องตรงกัน
                </li>
                <li>
                  เลขที่ Invoice และวันที่: Invoice number คือ เลขที่ Invoice
                  เพื่อกระบวนการบันทึกวันที่ใน Invoice
                  ในกรณีที่ทางลูกค้าใช้สิทธิพิเศษทางการค้า (FTA) Invoice number
                  และ Invoice Date ต้องตรงกับ FORM C/O ที่ใช้
                </li>
              </ul>

              <ul>
                2. PACKING LIST (บัญชีรายละเอียดสินค้า)
                <li>
                  ชื่อและที่อยู่ของผู้ส่งสินค้าและผู้นำเข้าสินค้า:
                  ในกรณีใช้สิทธิพิเศษทางการค้า FTA
                  ชื่อและที่อยู่ของผู้ส่งออกต้องตรงกับเอกสาร BILL OF LOADING /
                  FORM C/O
                </li>
                <li>
                  วันที่ออกใบกำกับบันทึก: หมายเลขเอกสารการอ้างอิง
                  คำสั่งซื้อสินค้า หรือสัญญาที่ได้ระบุเอาไว้ล่วงหน้า
                </li>
                <li>
                  Description: รายละเอียดของสินค้า (ชื่อสินค้า) ตรงกับ INVOICE
                </li>
                <li>
                  Measurement: ปริมาณหรือขนาดของสินค้า ตรงกับ BILL OF LOADING
                </li>
                <li>Net Weight: น้ำหนักสุทธิของสินค้า</li>
                <li>Gross Weight: น้ำหนักรวมของสินค้าและบรรจุภัณฑ์</li>
                <li>
                  Number of Packages: จำนวนหีบห่อของสินค้า ตรงกับ BILL OF
                  LOADING และ FORM C/O
                </li>
                <li>
                  Shipping Mark: เครื่องหมายบนหีบห่อของสินค้า ตรงกับ BILL OF
                  LOADING และ FORM C/O
                </li>
              </ul>

              <ul>
                3. BILL OF LOADING (ใบตราส่งสินค้า)
                <li>
                  <ul>
                    ชื่อผู้ขาย/ส่งสินค้า: ในส่วนนี้จะมี 3 กรณี
                    <li>
                      กรณีที่ไม่ใช้สิทธิพิเศษทางการค้า (FTA)
                      ชื่อผู้ขายจะตรงกับเอกสาร INVOICE / PACKING LIST
                    </li>
                    <li>
                      กรณีที่ใช้สิทธิพิเศษทางการค้า (FTA)
                      แต่เป็นการขายตรงที่ไม่ผ่านบุคคลที่สาม (THIRD PARTY)
                      ส่วนนี้ชื่อผู้ขายจะตรงกับ INVOICE / PACKING LIST / FORM
                      C/O
                    </li>
                    <li>
                      กรณีที่ใช้สิทธิพิเศษทางการค้า (FTA)
                      แต่เป็นการซื้อขายที่ผ่านบุคคลที่สาม (THIRD PARTY)
                      ส่วนนี้ชื่อผู้ขายจะตรงกับ PACKING LIST / FORM C/O
                    </li>
                  </ul>
                </li>
                <li>
                  รายละเอียดสินค้า: รายละเอียดของสินค้าและบริการที่ถูกสั่งซื้อ
                  รวมถึงปริมาณ ราคาต่อหน่วย และมูลค่ารวม ในกรณีสินค้าที่เป็น
                  FREE OF CHARGE (FOC) ต้องมีสถานะในใบขนสินค้าว่าเป็น FOC
                  และราคาต่อหน่วยของตัว FOC ต้องมีราคาต่อหน่วยไม่น้อยกว่า 60%
                  ของราคาตลาด
                </li>
                <li>
                  ราคาและเงื่อนไขในการชำระเงิน:
                  ระบุราคาทั้งหมดโดยราคาที่ระบุไม่ต่ำกว่า 60% ของราคาตลาดใน
                  Invoice มิฉะนั้น PO (PRE-ORDER)
                  เพื่อวัตถุประสงค์ตรวจสอบราคาต้องตรงกัน
                </li>
                <li>
                  เลขที่ Invoice และวันที่: Invoice number คือ เลขที่ Invoice
                  เพื่อกระบวนการบันทึกวันที่ใน Invoice
                  ในกรณีที่ทางลูกค้าใช้สิทธิพิเศษทางการค้า (FTA) Invoice number
                  และ Invoice Date ต้องตรงกับ FORM C/O ที่ใช้
                </li>
              </ul>

              <h3>“WHY US”</h3>
              <p>
                การนำเข้าเครื่องสำอางอย่างถูกต้องตามกฎหมายเป็นกระบวนการที่ซับซ้อนและต้องการความเข้าใจในข้อกำหนดและเอกสารที่เกี่ยวข้อง
                เพื่อให้แน่ใจว่าสินค้าที่นำเข้ามานั้นมีคุณภาพและปลอดภัยสำหรับผู้บริโภค
              </p>

              <p>
                ทั้งนี้
                กระบวนการในการตรวจปล่อยและปัญหาที่อาจพบเมื่อมีการนำเข้าสินค้าระหว่างประเทศ
                ทีมงานของเรามีใบอนุญาตตัวแทนออกของและได้รับการรับรองผ่านกรมศุลกากร
                เราเข้าใจทุกระเบียบที่เกี่ยวข้อง
                ด้วยความเชี่ยวชาญในอุตสาหกรรมนี้ เราพร้อมแก้ไขปัญหา
                และให้ข้อมูลที่เป็นประโยชน์พร้อมดำเนินการตามข้อกำหนดและข้อปฏิบัติเพื่อให้แน่ใจว่าสินค้าจะได้รับการดูแลในระหว่างการขนส่งอย่างปลอดภัย
                หรือถูกจัดเก็บไว้ในที่ปลอดภัยตามข้อกำหนดอย่างเคร่งครัด
              </p>

              <h3>เกร็ดความรู้ฉลากเครื่องสำอาง</h3>
              <ul>
                <li>
                  ฉลากต้องมีข้อความภาษาไทยและชัดเจน: รวมถึงรายละเอียดสำคัญ เช่น
                  ชื่อผลิตภัณฑ์, ส่วนผสม, วิธีใช้, ข้อควรระวัง, เลขที่ใบรับแจ้ง
                </li>
                <li>ห้ามฉลากแสดงข้อกล่าวอ้างที่เกินจริงหรือหลอกลวง</li>
              </ul>

              <h3>เอกสารใบอนุญาตที่ใช้ตรวจปล่อยหน้าด่าน</h3>
              <ul>
                <li>เอกสาร LPI (LICENSE PER INVOICE)</li>
                <ul>
                    <li>ชื่อสินค้า, ประเทศผู้ผลิต, ขนาดบรรจุ</li>
                    <li>ต้องมีใบอนุญาตนำเข้าจาก อย.</li>
                </ul>
                <li>เอกสารกำกับแปล: ใช้สำหรับสินค้าที่ไม่มีฉลากภาษาไทย</li>
              </ul>

              <p>
                EASTHAI เป็นผู้เชี่ยวชาญด้านศุลกากร
                หากท่านมีความสนใจในการนำเข้า/ส่งออกเครื่องสำอาง
                ทีมงานของเรายินดีให้คำปรึกษาและบริการครบวงจร
                สามารถติดต่อเราเพื่อรับข้อมูลและคำแนะนำที่เป็นประโยชน์
                เพื่อให้การดำเนินการของท่านเป็นไปอย่างราบรื่นและถูกต้องตามกฎหมาย
              </p>
              <p>
                หากทานสนใจนําเขาสินค้าสามารถติดต่อ
                เราผู้เชี่ยวชาญในการดําเนนพิธีการศุลกากร พร้อมดูแลให้ทุกขั้นตอน
                สะดวก รวดเร็ว ตอบโจทย์ครบวงจร เบอร์โทรติดต่อ 081-358-9994 หรือ
                02-235-9100 #29
              </p>
            </section>

            <BlogShare url={url} />
            <ProgressBar />
          </div>
        </div>
        <div className="sidebarComponent">
          <Sidebar />
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Easblog;
