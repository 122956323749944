import React from "react";
import "./privacypolicy.css";
import { Container } from "@mui/material";

const Privacypolicy = () => {
  return (
    <div className="privacypolicy">
      <Container className="privacypolicyBody">
        <h2>Privacy and Policy for East Asia Shipping (Thailand) Ltd.</h2>

        <h5>
          <span>1. Introduction</span>
        </h5>

        <p>
          This Privacy and Policy document outlines the practices, procedures,
          and principles employed by East Asia Shipping (Thailand) Ltd., a
          prominent freight forwarder and customs agent based in Bangkok,
          Thailand, to protect the privacy and data of our valued clients and
          users.
        </p>

        <h5>
          <span>2. Information We Collect</span>
        </h5>

        <p>2.1 Personal Information</p>
        <p>
          East Asia Shipping (Thailand) Ltd. may collect personal information to
          ensure efficient customs clearance and shipping services. This
          information includes:
        </p>
        <ul>
          <li>Names</li>
          <li>Contact details (email, phone, and address)</li>
          <li>Identification documents (for customs and logistics purposes)</li>
        </ul>

        <p>2.2 Logistical Data</p>

        <p>
          We gather data related to shipments and cargo to facilitate tracking
          and transportation routes. This includes:
        </p>
        <ul>
          <li>Shipment tracking details</li>
          <li>Cargo weight and dimensions</li>
          <li>Transportation routes</li>
        </ul>

        <p>2.3 Payment Information</p>

        <p>
          When necessary for billing and invoicing, we collect payment
          information, which may include:
        </p>

        <ul>
          <li>Credit card information</li>
          <li>Bank account details</li>
        </ul>

        <h5>
          <span>3. How We Use Your Information</span>
        </h5>

        <p>3.1 Customs and Shipping</p>
        <p>
          We utilize your personal information to ensure accurate customs
          clearance, efficient shipping logistics, and timely delivery of your
          cargo.
        </p>

        <p>3.2 Billing</p>

        <p>
          Payment information is used for billing and invoicing purposes,
          ensuring transparency and compliance with financial regulations.
        </p>
        <p>3.3 Improvement</p>
        <p>
          Data may be used for internal analysis and process improvement. This
          enables us to enhance our services and offer you a better shipping
          experience.
        </p>
        <h5>
          <span>4. Data Security</span>
        </h5>

        <p>
          East Asia Shipping (Thailand) Ltd. employs stringent security measures
          to protect your data against unauthorized access, disclosure, or
          alteration. Your trust is our priority, and we are committed to
          safeguarding your information.
        </p>
        <h5>
          <span>5. Information Sharing</span>
        </h5>
        <p>5.1 Third-Party Partners</p>
        <p>
          We do not sell your personal information to third parties. However, we
          may share it with trusted partners, including customs authorities,
          shipping carriers, and other service providers when required for
          customs clearance and shipping processes.
        </p>
        <p>5.2 Legal Obligations</p>
        <p>
          In rare cases, we may disclose information when compelled by law or in
          response to a valid legal request, such as a court order.
        </p>
        <h5>
          <span>6. Data Retention</span>
        </h5>

        <p>
          We retain your data for as long as necessary to fulfill the purposes
          outlined in this policy or as required by applicable laws and
          regulations. When data is no longer needed, it is securely deleted or
          anonymized.
        </p>

        <h5>
          <span>7. Your Choices</span>
        </h5>

        <p>
          You have the right to request access, correction, or deletion of your
          personal information. If you have any concerns or require assistance
          regarding your data, please contact us at{" "}
          <a href="tel:022359100" className="contact-info">[02-235-9100].</a>
        </p>

        <h5>
          <span>8. Changes to this Policy</span>
        </h5>

        <p>
          East Asia Shipping (Thailand) Ltd. may update this policy periodically
          to reflect changes in our services, legal requirements, or industry
          best practices. We encourage you to review it regularly to stay
          informed about how we handle your data.
        </p>

        <h5>
          <span>9. Contact Us</span>
        </h5>

        <p>
          If you have any questions, concerns, or feedback regarding our privacy
          and policy, please do not hesitate to contact East Asia Shipping
          (Thailand) Ltd. at:
        </p>

        <p>
          <a href="tel:022359100" className="contact-info">[02-235-9100]</a>
        </p>

        <h5>
          <span>10. Compliance with Applicable Laws</span>
        </h5>

        <p>
          East Asia Shipping (Thailand) Ltd. is committed to complying with all
          relevant data privacy and security laws and regulations in Thailand
          and any other jurisdictions where we operate.
        </p>

        <h5>
          <span>11. Neutrality on Non-Related Issues</span>
        </h5>

        <p>
          At East Asia Shipping (Thailand) Ltd., our primary focus is delivering
          efficient freight forwarding and customs agent services. We remain
          neutral on issues not directly related to our services and operations,
          respecting the diverse opinions and perspectives of our clients and
          users.
        </p>
      </Container>
    </div>
  );
};

export default Privacypolicy;
