import React, { useEffect } from "react";
import Contact from "../contact/Contact";
import Sidebar from "../sidebar/Sidebar";
import BlogShare from "../blogShare/BlogShare";
import blog7cover from "../../images/blog7cover.png";
import "./easblog7.css";
import ProgressBar from "../progressbar/Progressbar";

const Easblog = () => {
  const url = "https://www.easthai.co/blog/machinery";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="easblog">
        <div className="blogArticle">
          <div className="blogContainer">
            <div className="blogArticleHeader">
              <div>July 1, 2024</div>
              <h1>คู่มือการนำเข้า เครื่องจักร: วิธีการและข้อกำหนด</h1>
              <div>By EASTHAI Team</div>
            </div>
            <img src={blog7cover} alt="Blog post" />
            <section>
              <h3>นำเข้าอย่างมีประสิทธิภาพ</h3>
              <p>
                การทำความเข้าใจและปฏิบัติตามขั้นตอนต่างๆ
                เป็นสิ่งสำคัญที่จะช่วยให้กระบวนการดำเนินไปอย่างราบรื่นและไม่เกิดปัญหาใดๆ
                การดำเนินการนำเข้าเครื่องจักรนั้นมีหลายขั้นตอนที่ต้องคำนึงถึง
                ตั้งแต่การตรวจสอบพิกัดภาษีและใบอนุญาต
                การเตรียมเอกสารการขนส่งและข้อมูลสำคัญต่างๆ
                ตลอดจนการจัดการด้านสิทธิประโยชน์และการลดหย่อนภาษี
                การปฏิบัติตามขั้นตอนเหล่านี้อย่างถูกต้องไม่เพียงแต่จะช่วยให้การนำเข้าเครื่องจักรเป็นไปอย่างมีประสิทธิภาพ
                แต่ยังช่วยลดความเสี่ยงในการเกิดความล่าช้าและค่าใช้จ่ายเพิ่มเติม.
              </p>
              <h3>แนวทางการนำเข้าเครื่องจักร</h3>
              <ul>
                บริการตรวจสอบพิกัดภาษีและใบอนุญาต:
                <li>
                  การตรวจสอบพิกัดอย่างถูกต้อง
                  จะช่วยลดขั้นตอนในการผ่านศุลกากรและเพื่อให้เป็นไปตามหลักพิกัดศุลกากรประเทศไทย
                  และตรวจสอบว่าสามารถใช้สิทธิลดอัตราศุลกากรสำหรับเขตการค้าเสรี
                  (จีน, อาเซียน)
                </li>
                <li>
                  ตรวจสอบพิกัดของเครื่องจักร
                  ว่าเครื่องจักรนั้นต้องขอใบอนุญาตในการนำเข้าหรือไม่ เช่น
                  ใบอนุญาตจากสำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม เป็นต้น
                </li>
              </ul>
              <ul>
                บริการจัดเตรียมข้อมูลสำคัญ:
                <li>ชื่อภาษาอังกฤษ รุ่นและยี่ห้อของเครื่องจักร</li>
                <li>รูปภาพหรือแคตตาล็อกของเครื่องจักร</li>
                <li>หน้าที่การใช้งานและประเภทพลังงานที่ใช้</li>
              </ul>
              <ul>
                บริการให้คำปรึกษาและตรวจสอบ:
                <li>
                  ผู้ชำนาญการศุลกากรของเราจะช่วยตรวจสอบข้อมูลดังกล่าว
                  เพื่อความถูกต้องและให้คำปรึกษาในการใช้สิทธิประโยชน์ต่างๆ เช่น
                  การลดหย่อนอากรศุลกากร
                  เพื่อให้ได้ประโยชน์สูงสุดในการนำเข้าเครื่องจักร
                </li>
              </ul>
            </section>
            <section>
              <h3>CUSTOMS CLEARANCE</h3>
              <ul>
                อัตราภาษีนำเข้าและตัวอย่างสิทธิประโยชน์ :
                <li>
                  โดยทั่วไปแล้ว เครื่องจักรจะมีอัตราภาษีนำเข้าอยู่ที่ 15%
                  แต่สามารถขอลดหย่อนหรือยกเว้นได้หากเข้าตามหลักเกณฑ์เงื่อนไข
                </li>
                <li>
                  ตาม มาตรา 28 ของพรบ.ส่งเสริมการลงทุน
                  ผู้ที่ได้รับการส่งเสริมจะได้รับยกเว้นอากรขาเข้าสำหรับเครื่องจักรที่ไม่สามารถผลิตในประเทศได้
                </li>
                <li>
                  มาตรา 29
                  อนุญาตให้ลดหย่อนอากรขาเข้าสำหรับเครื่องจักรได้เพียงกึ่งหนึ่ง
                  หรือในบางกรณีอาจไม่ได้รับการยกเว้นเลย
                </li>
              </ul>
              <h3>สิทธิประโยชน์จาก BOI</h3>
              <p>
                การนำเข้าเครื่องจักรได้รับสิทธิประโยชน์จาก Thailand Board of
                Investment (BOI) เพื่อลดต้นทุนและค่าใช้จ่ายในการนำเข้า เช่น
                การยกเว้นอากรขาเข้าสำหรับเครื่องจักรที่ไม่สามารถผลิตได้ในประเทศ
                และการลดหย่อนภาษีศุลกากร
                ช่วยให้ธุรกิจลงทุนได้อย่างมีประสิทธิภาพและเพิ่มขีดความสามารถในการแข่งขัน
                สิ่งนี้ไม่เพียงลดต้นทุน
                แต่ยังช่วยส่งเสริมการเติบโตของอุตสาหกรรมและการจ้างงานในประเทศ
                หากสนใจสามารถศึกษารายละเอียดเพิ่มเติมจากเว็บไซต์ BOI
                หรือติดต่อเราเพื่อขอคำปรึกษาเพิ่มเติม
              </p>
            </section>
            <section>    
              <p>
                บริษัทของเรามีบริการครบวงจรในการนำเข้าเครื่องจักร
                ทั้งการลงทะเบียน ตรวจสอบพิกัดภาษี
                และการจัดการกับสิทธิประโยชน์ต่างๆ
                เพื่อให้การนำเข้าเป็นไปอย่างราบรื่นและมีประสิทธิภาพสูงสุด
                เรามีผู้ชำนาญการศุลกากรคอยให้คำปรึกษาเพื่อช่วยในการดำเนินการทุกขั้นตอน
              </p>
              <p>
                EASTHAI เป็นผู้เชี่ยวชาญด้านศุลกากร
                หากท่านมีความสนใจในการนําเข้า/ส่งออกเครื่องจักร
                ทีมงานของเรายินดี ให้คำปึกษาและบริการครบวงจร
                สามารถติดต่อเราเพื่อรับข้อมูลและคำแนะนําทีเป็นประโยชน์
                เพื่อให้การดําเนินการ
                ของท่านเป็นไปอย่างราบรื่นและถูกต้องตามกฎหมาย
              </p>
              <p>
                หากทานสนใจนําเขาสินค้าสามารถติดต่อ
                เราผู้เชี่ยวชาญในการดําเนนพิธีการศุลกากร พร้อมดูแลให้ทุกขั้นตอน
                สะดวก รวดเร็ว ตอบโจทย์ครบวงจร เบอร์โทรติดต่อ 081-358-9994 หรือ
                02-235-9100 #29
              </p>
            </section>

            <BlogShare url={url} />
            <ProgressBar />
          </div>
        </div>
        <div className="sidebarComponent">
          <Sidebar />
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Easblog;
