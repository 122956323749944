import React from 'react'
import './sliderBrandTestimonial.css'

import partnerlogo1 from '../../images/partnerlogo1.svg'
import partnerlogo3 from '../../images/partnerlogo3.svg'
import partnerlogo4 from '../../images/partnerlogo4.svg'
import partnerlogo5 from '../../images/partnerlogo5.svg'
import partnerlogo6 from '../../images/partnerlogo6.svg'
import partnerlogo7 from '../../images/partnerlogo7.svg'
import partnerlogo8 from '../../images/partnerlogo8.svg'
import partnerlogo9 from '../../images/partnerlogo9.svg'

const SliderBrandTestimonial = () => {


  return (
    <>
            <div className="sliderBrandHeader">
            <h1>As Trusted by</h1>
            </div>
        <div className='sliderBrand'>
            <div className="slideBrand-track">
            <div className="slideBrand">
                <img src={partnerlogo1} alt="shipping freight by east asia shipping" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo6} alt="shipping freight by east asia shipping" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo3} alt="shipping freight by east asia shipping" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo4} alt="shipping freight by east asia shipping" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo5} alt="shipping freight by east asia shipping" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo7} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo8} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo9} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo1} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo6} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo3} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo4} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo5} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo7} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo8} alt="" />
            </div>
            <div className="slideBrand">
                <img src={partnerlogo9} alt="" />
            </div>
            </div>
        </div>

        </>


  )
}

export default SliderBrandTestimonial