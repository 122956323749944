import React from 'react'
import './sidebar.css'
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import blog1card from '../../images/blog1card.png'
import blog2card from '../../images/blog2card.png'
import blog6card from '../../images/blog6card.png'
import blog4card from '../../images/blog4card.png'
import blog5card from '../../images/blog5card.png'


const Sidebar = () => {
  return (
    <>
    <div className="sidebarHeader">
    <h1>You may also like</h1>
    </div>
    <div className='sidebar'>
        <div className="sidebarPost">
            <img src={blog5card} alt="FCL LCL คือ" />

            <Link to='/blog/fcl-lcl' className='sidebarPostBtn'>
            <button>
                Read Article
            </button>
            </Link>
        </div>
        <div className="sidebarPost">
            <img src={blog4card} alt="หน้าที่ชิปปิ้ง" />

            <Link to='/blog/shippingbroker' className='sidebarPostBtn'>
            <button>
                Read Article
            </button>
            </Link>
        </div>
         <div className="sidebarPost">
            <img src={blog2card} alt="นำเข้าอาหารสัตว์" />

            <Link to='/blog/animal-feed-customs-clearance' className='sidebarPostBtn'>
            <button>
                Read Article
            </button>
            </Link>

        </div>
        <div className="sidebarPost">
            <img src={blog6card} alt="นำเข้าเครื่องสำอาง" />

            <Link to='/blog/cosmetics' className='sidebarPostBtn'>
            <button>
                Read Article
            </button>
            </Link>
        </div>

        <div className="sidebarPost">
            <img src={blog1card} alt="Shippingmark คือ" />

            <Link to='/blog/shippingmark' className='sidebarPostBtn'>
            <button>
                Read Article
            </button>
            </Link>
        </div>
        
        
        <div className="sidebarPostAll">
            <Link to='/blog' className='sidebarPostAllBtn'>
            <button>
            View all
                <KeyboardArrowRightIcon sx={{ fontSize: '24px' }} />
            </button>
            </Link>
        </div>
    </div>
    </>
  )
}

export default Sidebar