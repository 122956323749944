import React, { useEffect } from "react";
import "./inlandtransportation.css";
import { Helmet } from "react-helmet";
import Contact from "../../components/contact/Contact";
import GetQuotation from "../../components/getQuotation/GetQuotation";
import { Container } from "@mui/material";
import Slider from "../../components/slider/Slider";

import inland1 from "../../images/inland1.png";
import inland2 from "../../images/inland2.png";

const Inlandtransportation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>EASTHAI - ขนส่งสินค้าภายในประเทศ</title>
      </Helmet>
      <div className="inlandHeader">
        <div className="inlandHeaderText">
          <h1>Inland Transportation</h1>
          <h3>บริการขนส่งภายในประเทศ</h3>
        </div>
      </div>

      <Container className="inlandContainer">
        <div className="inlandContent">
          <div className="inlandContent-left">
            <h1>บริการขนส่งภายในประเทศ คือ</h1>
            <p>
              การขนส่งภายในประเทศ (Inland Transportation) เป็นการ
              การขนส่งสินค้าระหว่างท่าเรือหรือสนามบินไปยังปลายทางของลูกค้า
              โดยใช้รถบรรทุกหรือยานพาหนะอื่น ๆ
              เพื่อให้การจัดส่งเป็นไปอย่างรวดเร็วและปลอดภัย
            </p>
            <p>
              Inland transportation refers to the movement of goods between
              ports or airports to the customer's destination using trucks or
              other vehicles, ensuring fast and safe delivery.
            </p>
          </div>

          <div className="inlandContent-right">
            <img src={inland1} alt="" />
          </div>
        </div>

        <div className="inlandContent2">
          <h1>บริการของเรา</h1>
          <h3>
            We offer a variety of vehicles in all sizes to suit your needs.
          </h3>
          <p>
            บริการขนส่งจากต้นทางไปยังปลายทางด้วยความชำนาญในด้านเส้นทาง
            โดยคำนึงถึงความปลอดภัยของสินค้ามาเป็นอันดับหนึ่ง
            พร้อมให้คำปรึกษาและออกแบบโซลูชั่นการขนส่งที่เหมาะสมกับธุรกิจแต่ละประเภท
            พร้อมบริการขนส่งด้วยยานพาหนะที่หลากหลาย
            โดยมีพาร์ทเนอร์มืออาชีพรับหน้าที่ประสานงานอย่างราบรื่นในทุกขั้นตอน
            เพื่ออำนวยความสะดวกให้แก่ลูกค้าให้มากที่สุด
          </p>

          <p>
            We provide transportation services from origin to destination with
            expertise in route planning, prioritizing the safety of your goods
            above all. We offer consultations and design customized
            transportation solutions tailored to each business. Our services
            include a variety of vehicles, coordinated by professional partners
            to ensure smooth operations at every step, maximizing convenience
            for our customers.
          </p>

          <div className="inlandContent2-detail">
            <ul>
              <span>Trucking Service</span>
              <li>รถบรรทุก 4 ล้อ หรือรถกระบะ</li>
              <li>รถกระบะตู้ทึบ</li>
              <li>รถบรรทุก 6 ล้อ</li>
              <li>รถบรรทุก 10 ล้อ</li>
              <li>รถเทรลเลอร์</li>
              <li>รถพ่วง</li>
            </ul>

            <img src={inland2} alt="Inland Transportation" />
          </div>
        </div>
      </Container>

      <Slider />
      <GetQuotation />
      <Contact />
    </>
  );
};

export default Inlandtransportation;
