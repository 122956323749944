import React, { useEffect } from "react";
import Contact from "../contact/Contact";
import Sidebar from "../sidebar/Sidebar";
import BlogShare from "../blogShare/BlogShare";
import blog8cover from "../../images/blog8cover.png";
import "./easblog8.css";
import ProgressBar from "../progressbar/Progressbar";

const Easblog = () => {
  const url = "https://www.easthai.co/blog/import-tax";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="easblog">
        <div className="blogArticle">
          <div className="blogContainer">
            <div className="blogArticleHeader">
              <div>July 7, 2024</div>
              <h1>
                การจัดเก็บภาษีมูลค่าเพิ่มสำหรับของที่มีราคาไม่เกิน 1,500 บาท
              </h1>
              <div>By EASTHAI Team</div>
            </div>
            <img src={blog8cover} alt="Blog post" />
            <section>
              <h3>
                การจัดเก็บภาษีมูลค่าเพิ่มสำหรับของที่มีราคาไม่เกิน 1,500 บาท
              </h3>
              <p>
                ในปัจจุบัน การจัดเก็บภาษีมูลค่าเพิ่ม (VAT)
                เป็นเรื่องสำคัญที่รัฐบาลต้องดำเนินการให้เกิดความเป็นธรรมระหว่างผู้ซื้อในประเทศและผู้ซื้อที่นำเข้าสินค้าจากต่างประเทศ
                กระทรวงการคลังได้ออกมาตรการใหม่ที่มุ่งเน้นการแก้ไขปัญหาความไม่เป็นธรรมในการเก็บภาษีมูลค่าเพิ่มนี้
              </p>
              <h3>สาระสำคัญของประกาศใหม่</h3>
              <h4>
                1. การเก็บภาษีมูลค่าเพิ่มสำหรับของที่มีราคาไม่เกิน 1,500 บาท
              </h4>
              <ul>
                ตามประกาศใหม่ของกระทรวงการคลัง ระบุว่า ของที่มีมูลค่าไม่เกิน
                1,500 บาท
                ซึ่งนำเข้าจากต่างประเทศจะต้องเสียภาษีมูลค่าเพิ่มโดยกรมศุลกากร
                โดยมีรายละเอียดการจัดเก็บดังนี้:
                <li>
                  ของที่มีมูลค่า 0-1 บาท: ได้รับการยกเว้นอากรศุลกากร
                  ภาษีสรรพสามิต และภาษีมูลค่าเพิ่ม
                </li>
                <li>
                  ของที่มีมูลค่าตั้งแต่ 1-1,500 บาท:
                  จะได้รับการยกเว้นอากรศุลกากร แต่ต้องชำระภาษีสรรพสามิต
                  และภาษีมูลค่าเพิ่ม
                </li>
              </ul>
              <h4>2. ขั้นตอนการดำเนินการ</h4>
              <ul>
                เพื่อให้การจัดเก็บภาษีมูลค่าเพิ่มเป็นธรรมและมีประสิทธิภาพ
                กระทรวงการคลังได้กำหนดให้:
                <li>
                  กรมศุลกากร:
                  ดำเนินการเก็บภาษีมูลค่าเพิ่มจากของที่นำเข้าจากต่างประเทศผ่านทางระบบ
                  Platform ของผู้นำเข้าที่อยู่ในประเทศไทย
                </li>
                <li>
                  ผู้นำเข้า:
                  จะต้องชำระภาษีมูลค่าเพิ่มผ่านทางระบบอิเล็กทรอนิกส์ของกรมศุลกากร
                </li>
              </ul>
              <h4>3. ระยะเวลาการบังคับใช้</h4>
              <ul>
                ประกาศนี้จะมีผลบังคับใช้ตั้งแต่วันที่ 5 กรกฎาคม 2567 จนถึงวันที่
                31 ธันวาคม 2567
              </ul>
            </section>
            <section>
              <h3>ผลของประกาศ</h3>
              <ul>
                การออกประกาศใหม่ของกระทรวงการคลังและกรมศุลกากรนี้
                มีจุดประสงค์เพื่อ:
                <li>ลดขั้นตอนและเวลาในการเสียภาษีและอากรศุลกากรของผู้นำเข้า</li>
                <li>
                  สร้างความเป็นธรรมในการจัดเก็บภาษีระหว่างของที่นำเข้าจากต่างประเทศและของในประเทศ
                </li>
                <li>เพิ่มประสิทธิภาพในการจัดเก็บรายได้ให้กับประเทศ</li>
              </ul>
              <h3>ประกาศที่เกี่ยวข้อง</h3>
              <h4>ประกาศกรมศุลกากรที่ 116/2567</h4>
              <p>
                กำหนดราคาของที่นำเข้าจะได้รับการยกเว้นอากรศุลกากร ภาษีสรรพสามิต
                และภาษีมูลค่าเพิ่ม มูลค่าไม่เกิน 1 บาท
                ซึ่งจะมีผลทำให้ของที่นำเข้าที่มีมูลค่า 0-1 บาท
                ได้รับยกเว้นภาษีศุลกากร ภาษีสรรพสามิต และภาษีมูลค่าเพิ่ม
              </p>
              <h4>ประกาศกระทรวงการคลัง</h4>
              <p>
                เรื่อง การยกเว้นอากรศุลกากรสำหรับของที่มีมูลค่าไม่เกิน 1,500 บาท
                เพื่อลดขั้นตอนการเสียภาษีและอากรศุลกากรของผู้นำเข้า
              </p>
            </section>
            <section>
              <p>
                การบังคับใช้ประกาศของกระทรวงการคลังและกรมศุลกากรครั้งนี้
                จะช่วยให้เกิดความเท่าเทียมกันระหว่างของที่นำเข้าจากต่างประเทศและของในประเทศ
                ที่ต้องเสียภาษีสรรพสามิตและภาษีมูลค่าเพิ่มเช่นเดียวกัน
                โดยสามารถติดตามรายละเอียดเพิ่มเติมได้จากประกาศของกรมศุลกากรและกระทรวงการคลัง
                เพื่อให้คุณทราบข้อมูลที่ครบถ้วนและเป็นปัจจุบันที่สุด
              </p>
              <p>
                หากต้องการนำเข้าสินค้าหรือมีข้อสงสัยใด ๆ
                สามารถติดต่อเราเพื่อรับคำปรึกษาและคำแนะนำในการนำเข้า
                เรามีผู้เชี่ยวชาญด้านศุลกากรที่พร้อมให้การสนับสนุนและช่วยเหลือคุณเสมอ
                EASTHAI เป็นผู้เชี่ยวชาญด้านศุลกากร
                หากท่านมีความสนใจในการนำเข้า/ส่งออก
                ทีมงานของเรายินดีให้คำปรึกษาและบริการครบวงจร
                สามารถติดต่อเราเพื่อรับข้อมูลและคำแนะนำที่เป็นประโยชน์
                เพื่อให้การดำเนินการของท่านเป็นไปอย่างราบรื่นและถูกต้องตามกฎหมาย
                หากท่านสนใจนำเข้าสินค้าสามารถติดต่อเราผู้เชี่ยวชาญในการดำเนินพิธีการศุลกากร
                พร้อมดูแลทุกขั้นตอนอย่างสะดวก รวดเร็ว และครบวงจร เบอร์โทรติดต่อ
                081-358-9994 หรือ 02-235-9100 #29
              </p>
            </section>

            <BlogShare url={url} />
            <ProgressBar />
          </div>
        </div>
        <div className="sidebarComponent">
          <Sidebar />
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Easblog;
