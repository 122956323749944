import React from "react";
import "./b2bSolutions.css";
import { Link } from "react-router-dom";

const B2bSolutions = () => {
  return (
    <div className="b2b-container">
      <div className="b2b-header">
        <div className="b2b-box">
        <h1>สินค้าที่นิยมขนส่งกันเรา</h1>
        <div className="b2b-textbox">
        <h5 className="english">
          We specialize in customs clearance for this product category, ensuring
          that all regulations and requirements are followed accurately. Our
          expertise helps make the import and export process smooth, reducing
          delays.
        </h5>
        <h5 className="thai">
          เรามีความเชี่ยวชาญในการดำเนินพิธีการศุลกากรสำหรับหมวดหมู่สินค้านี้
          โดยมั่นใจว่าทุกกฎระเบียบและข้อกำหนดจะได้รับการปฏิบัติตามอย่างถูกต้อง
          ความเชี่ยวชาญของเราช่วยให้การนำเข้าและส่งออกเป็นไปอย่างราบรื่น
          ลดความล่าช้า
        </h5>
        </div>
        </div>

        <div className="b2b-cards">
          <div className="b2b-card">
            <h2>Animal Feed</h2>
            <h4>นำเข้าอาหารสัตว์</h4>
            <ul>
              <li>Poultry Feed</li>
              <li>Cattle Feed</li>
              <li>Pet Food</li>
              <li>Fish Feed</li>
            </ul>
            <Link to="/blog/animal-feed-customs-clearance">
              <button>Read more</button>
            </Link>
          </div>
          <div className="b2b-card">
            <h2>Beauty Product</h2>
            <h4>เครื่องสำอางและผิวหนัง</h4>
            <ul>
              <li>Moisturizing Cream</li>
              <li>Foundation Makeup</li>
              <li>Lipstick</li>
              <li>Facial Cleanser</li>
            </ul>
            <Link to="/blog/cosmetics">
              <button>Read more</button>
            </Link>
          </div>
          <div className="b2b-card">
            <h2>Audio System</h2>
            <h4>อุปกรณ์เครื่องเสียง</h4>
            <ul>
              <li>Headphones</li>
              <li>Home Therter System</li>
              <li>Soundbars</li>
              <li>Bluetooth Speakers</li>
            </ul>
            <Link to="/business-solutions">
              <button>Read more</button>
            </Link>
          </div>
          <div className="b2b-card">
            <h2>Machinery part</h2>
            <h4>เครื่องจักรและอะไหล่</h4>
            <ul>
              <li>Machine component</li>
              <li>Engine component</li>
              <li>Robot/Cobot</li>
              <li>Bearings</li>
            </ul>
            <Link to="/blog/machinery">
              <button>Read more</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2bSolutions;
